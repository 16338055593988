@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'elatina';
  src: url('../fonts/elatina.otf') format('opentype'),
  /* Add additional font formats if needed */
}

@font-face {
  font-family: 'Axiata Bold';
  src: url('../fonts/axiata_bold.otf') format('opentype');
  /* Add additional font formats if needed */
}

@font-face {
  font-family: 'Axiata Book';
  src: url('../fonts/axiata_book.otf') format('opentype');
  /* Add additional font formats if needed */
}

@font-face {
  font-family: 'Axiata Medium';
  src: url('../fonts/axiata_medium.otf') format('opentype');
  /* Add additional font formats if needed */
}

.custom-font {
  // font-family: 'elatina', sans-serif;
}

.headings-font {
  font-family: 'Axiata Bold', sans-serif;
}

.captions-font  {
  font-family: 'Axiata Book';
}

#root{
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: 'Axiata Medium', sans-serif;
}

.back-drop{
  background: url("../assets/bgImage.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh
}

.back-drop-grad{
  background: url("../assets/bgGradiantImage.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh
}

.back-drop-grad-btn{
  background: url("../assets/btnGrad.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}


.back-drop-get-started{
  background: url("../assets/bgImage.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.no-fillers{
  z-index: 2;
}

.styled-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
  z-index: 10;
}

.styled-scroll::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #E2E8F0;
}

.hidescrl::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit (Chrome, Safari, etc.) */
}

:root{
  --toastify-color-success: #D1FAE5;
  --toastify-text-color-success: #334155;
  --toastify-text-color-error: #334155;
  --toastify-color-error: #FED7D7;
  --toastify-icon-color-success: #10B981 !important;
  --toastify-icon-color-error: #E53E3E !important;
}

.Toastify__toast--error{
  div{
    div{
      svg{
        color: red;
      }
    }
  }
}

.Toastify__toast-icon{
  color: #10B981;
}
.Toastify__close-button{
color: #334155;
}

.Toastify__toast-container {
font-size: 15px;
}